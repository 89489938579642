/**
 * Store some variables that we want to access
 * here and there
 */

/**
 * The routes are good to be consistent with.
 */
export const ADMIN_HOME_PATH = 'admin'

export const ADMIN_START_PATH = ['/', ADMIN_HOME_PATH, 'start']